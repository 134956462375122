import React from 'react';
import { LogOut } from 'lucide-react';
import { useAuth } from '../Authenticator';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { getSubDomain } from '../lib/utils';
interface ThankYouPageProps {
  message?: string;
  onContinueShopping?: () => void;
  onViewOrderDetails?: () => void;
}

const ThankYouPage: React.FC<ThankYouPageProps> = ({
  message = "We appreciate your purchase! A confirmation email has been sent to you with the order details.",
}) => {
  const navigate = useNavigate();
  const { logoutHandler } = useAuth();
  const thankYouLogoutHandler = ()=>{
    logoutHandler();
    navigate("/")
  }
  
  const handleContinueSession = () => {
    navigate('/'); 
  };

  return (
    <div className="thank-you-page">
      <div className="thank-you-content">
        <h1>Thank You!</h1>
        <p>{message}</p>

        {/* Success Animation */}
        <div className="animation-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            className="checkmark"
          >
            <circle cx="26" cy="26" r="25" fill="none" className="checkmark-circle" />
            <path fill="none" className="checkmark-check" d="M14 27l8 8 16-16" />
          </svg>
        </div>

        {/* Action Buttons */}
        <div className="actions">
          <button className="primary-btn" onClick={handleContinueSession}>
            Continue Session
          </button>
          <button className="secondary-btn" onClick={thankYouLogoutHandler}>
            <LogOut />  &nbsp;&nbsp;Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
