import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Languages, ChevronDown } from "lucide-react";
import SignaturePad from "react-signature-canvas";
import { LoaderCircle } from "lucide-react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { updateUserIntakeData } from "../APIHandler/ApiHandler";
import { useAuth } from "../Authenticator";
import { convertBase64ToBlob } from "../lib/utils";
import { storage } from "../config/firebaseConfig";
import dayjs from "dayjs";
import { ActionTypes } from "../reducer/actionTypes";
import Drawer from "../Modals/Drawer";
import { X } from "lucide-react";
import Cookies from "js-cookie";

const SignatureDrawer = ({ isOpen, onClose, setUploadsignatureUrl }: SignatureDrawerProps) => {
  const { currentUser, setStellaData, stellaData, socketRef, customUserData } = useAuth();
  const cookie_token = Cookies.get("authToken");

  const uniqueID = crypto.randomUUID().toString();
  const [signatureStatus, setSignatureStatus] = useState<string>("idle");

  const [openSignatureEmptyPopup, setOpenSignatureEmptyPopup] = useState(false);

  const signatureCanvasRef = useRef<any>(null);


  const uploadSignatureToFirebase = async (base64Image: string) => {


    if (!cookie_token) return;

    try {
      //const dirName = dayjs().format("MMMM_YYYY");
      const blob = convertBase64ToBlob(base64Image);
      const storageRef = ref(
        storage,
        `/clinic-data-storage/clients/${currentUser?.Country}/${dayjs().format(
          "YYYY-MMM-DD_HH:mm:ss"
        )}_${currentUser?.Name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          //console.log("Uploading...", percent);
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadsignatureUrl(url);
            await updateUserIntakeData(cookie_token, currentUser?.UserId, url);
            const type = 2;

            const lastChatEntry =
              stellaData?.chatHistory[stellaData?.chatHistory?.length - 1] || {};
            const currDataNew = {
              service_id: lastChatEntry?.service_id || 50,
              message_id: "",
              question_id: lastChatEntry?.question_id || 0,
              type: type,
              time_stamp: Date.now(),
              session_id: stellaData?.session_id,
              user_id: stellaData?.profile?.user_id,
              registered_id: stellaData?.profile?.user_id,
              classify: "User",
              responseFrom: "User",
              options: null,
              mode: customUserData?.mode?.code || "gn01",
              welcome_message: stellaData?.customData?.welcome_message,
              expert_id: stellaData?.customData?.expert?.id,
              raw_intent: "after_consent",
              signature_url: url,
            };

            socketRef?.current?.send(JSON.stringify(currDataNew));

            let tempChatHistory = [...stellaData?.chatHistory];

            setStellaData({
              ...stellaData,
              modalData: {},
              chatHistory: [...tempChatHistory, currDataNew],
            });

            onCloseSignature();
            signatureCanvasRef?.current?.clear();
          } catch (error) {
            console.error("Error getting download URL:", error);
          } finally {
            setSignatureStatus("idle");
          }
        }
      );
    } catch (error) {
      console.error("Error while uploading");
      setSignatureStatus("idle");
    }
  };

  const onCloseSignature = () => {
    onClose();
  };

  const clearSignature = () => {
    if (signatureCanvasRef) {
      signatureCanvasRef?.current?.clear();
    }
  };

  const saveSignature = () => {
    if (signatureCanvasRef) {
      if (signatureCanvasRef.current.isEmpty()) {
        setOpenSignatureEmptyPopup(true);
        return;
      }

      const signatureImage: any =
        signatureCanvasRef?.current?.toDataURL("image/png");

      setSignatureStatus("uploading");
      uploadSignatureToFirebase(signatureImage);
      // console.log("Signature Image:", signatureImage);
    }
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-black/10 backdrop-blur-md bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white dark:bg-[#252B39] px-3 pt-3 sm:pt-0 sm:px-4 text-left shadow-xl  transition-all sm:my-8 sm:w-[600px] sm:p-6 max-h-screen  w-full CustomDrawer">
                  <div className="">
                    <div className="mt-3 text-center sm:mt-5 flex flex-col">
                      <>
                        {signatureStatus === "uploading" ? (
                          <div className="absolute top-0 left-0 z-[200] w-full h-full flex items-center justify-center bg-gray-500/40 dark:bg-black/20 backdrop-blur-md">
                            <LoaderCircle className="w-10 h-10 text-white animate-spin" />
                          </div>
                        ) : null}
                        <h3 className="text-xl font-semibold leading-6 text-gray-900 dark:text-white mb-10">
                          Add Your Signature
                        </h3>
                        <SignaturePad
                          ref={signatureCanvasRef}
                          minDistance={0}
                          //penColor="var(--primary-base-color)"
                          canvasProps={{
                            width: 500, // Set width explicitly if needed
                            height: 300, // Set height explicitly if needed
                            className:
                              "h-[300px] border border-slate-400/60 rounded-xl ",
                          }}
                        />

                        <div className="py-3 flex flex-col md:flex-row md:justify-end gap-2 max-w-sm w-full md:ml-auto">
                          <button
                            style={{ color: "var(--primary-base-color)" }}
                            className="py-2.5 px-3 md:px-5 rounded-full md:rounded-lg border border-slate-400  text-sm font-medium"
                            onClick={clearSignature}
                          >
                            Clear
                          </button>

                          <button
                            onClick={saveSignature}
                            style={{ backgroundColor: "var(--primary-base-color)" }}
                            className="py-2.5 px-3 md:px-5 rounded-full md:rounded-lg bg-#002155 text-white text-sm font-medium"
                          >
                            Save
                          </button>
                        </div>
                      </>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Drawer
        isOpen={openSignatureEmptyPopup}
        onClose={() => setOpenSignatureEmptyPopup(false)}
      >
        <button
          type="button"
          className="absolute right-2 top-2 rounded-full w-8 h-8 bg-[#f2f3f4] dark:bg-[#3a4358]  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() => setOpenSignatureEmptyPopup(false)}
        >
          <span className="sr-only">Close panel</span>
          <X className="h-6 w-6 m-auto" aria-hidden="true" />
        </button>
        <div className="pt-3">
          <h3 className="text-xl font-semibold leading-6 text-gray-900 dark:text-white mb-1">
            Your Signature is empty!
          </h3>
          <p className="text-slate-600">Please Add signature before saving</p>

          <div className="mt-10 mb-5 md:mb-0">
            <button
              onClick={() => setOpenSignatureEmptyPopup(false)}
              className="w-full md:w-max py-2.5 px-3 md:px-5 rounded-full md:rounded-lg border border-slate-400/80 text-sm font-medium"
            >
              Close
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SignatureDrawer;
