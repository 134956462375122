import { Button, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import WaitingLoaderVideos from "../Components/WaitingLoaderVideos";
import { get_waiting_session_loader_video, GetSessionCountData } from "../APIHandler/ApiHandler.js";
import CustomProgressBar from "../Components/CustomProgressBar.jsx";
import JoinNowModal from "../Modals/JoinNowModal";
import Lottie from "lottie-react";
import waiting_loader_video from "../timeranimation.json";
const WaitingToJoinSession = () => {
  const [waitingVideosList, setWaitingVideosList] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  useEffect(() => {
    const body = {
      post_type: "video",
      page: 0,
      count: 10,

      country: "Australia",
    };

    const fetchVideoData = async () => {
      setVideoLoading(true);
      try {
        const response = await get_waiting_session_loader_video(body);
        if (response?.ResponseCode === 200) {
          setWaitingVideosList(response?.data?.feed);
          setVideoLoading(false);
        }
      } catch (err) {
        console.error("Error fetching video data:", err);
        setVideoLoading(false);
      }
    };
    fetchVideoData();
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const sessionData = await GetSessionCountData();
        if (sessionData && Object.keys(sessionData)?.length < 1) {
          setJoinModalOpen(true);
        }
      } catch (error) {
        console.error("Failed to check session:", error);
      }
    };

    // Run checkSession every 1 minute
    const intervalId = setInterval(checkSession, 60000);

    // Run it immediately on component mount
    checkSession();

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);



  return (
    <Container maxWidth="lg">
      <div className="w-full h-full">
        <div className="py-20 flex flex-col items-center justify-center">
          <h5 className="top_heading_text">
            You’re in the queue because others are currently in sessions.
          </h5>
          <p className="small_description">
            When it’s your turn, you’ll have 60 seconds to join the session.
          </p>
         
          <Lottie animationData={waiting_loader_video} />
        </div>
        <div className="max-w">
          <h3 className="Videos_heading py-8">
            Make the most of your wait—explore these resources!
          </h3>
          <div className="w-full">
            <WaitingLoaderVideos
              waitingVideosList={waitingVideosList}
              videoLoading={videoLoading}
            />
          </div>
        </div>
        <JoinNowModal isOpen={joinModalOpen} closeCallback={() => setJoinModalOpen(false)} />
      </div>
    </Container>
  );
};

export default WaitingToJoinSession;
