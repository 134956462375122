import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WaitingVideoCard from "./WaitingVideoCard";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import "../App.css";

// Define types for the props
interface Video {
  name: string;
  imageUrl: string;
}

interface WaitingLoaderVideosProps {
  waitingVideosList: Video[];
  videoLoading: boolean;
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={` shadow-lg before:content-none after:content-none before:disabled:text-red-800 `}
      style={{
        ...style,
        height: "40px",
        width: "40px",
        background: "#F2F4F7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        position: "absolute",
        top: "50%",
        zIndex: 1000,
        right: "-18px",
        transform: "translateY(-50%)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <ArrowForwardIos style={{ color: "#a8a7a7", marginLeft: "6px" }} />{" "}
      {/* Make the arrow black */}
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={` shadow-lg before:content-none after:content-none `}
      style={{
        ...style,
        height: "40px",
        width: "40px",
        background: "#F2F4F7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        position: "absolute",
        top: "50%",
        zIndex: 1000,
        left: "-15px",
        transform: "translateY(-50%)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <ArrowBackIos style={{ color: "#a8a7a7", marginLeft: "6px" }} />{" "}
    </div>
  );
}
const WaitingLoaderVideos: React.FC<WaitingLoaderVideosProps> = ({
  waitingVideosList,
  videoLoading,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCardClick = (index: number) => {
    setActiveIndex(index);
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="">
      {waitingVideosList?.length > 0 && (
        <Slider {...settings}>
          {waitingVideosList?.map((videos, index) => (
            <div key={index}>
              <WaitingVideoCard
                handleCardClick={handleCardClick}
                videos={videos}
                activeIndex={activeIndex}
                index={index}
              />
            </div>
          ))}
        </Slider>
      )}

     
    </div>
  );
};

export default WaitingLoaderVideos;
