import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function JoinNowModal({ isOpen, closeCallback }) {
  const [open, setOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(60 * 1); // 15 minutes countdown in seconds
  const navigate = useNavigate();
  const intake_data = JSON.parse(window.localStorage.getItem("intake_data"));

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) {
      startCountdown();
      const notificationSound = new Audio(
        "/assets/mixkit-uplifting-bells-notification-938.wav"
      );
      notificationSound.play().catch((error) => {
        console.error("Error playing sound:", error);
      });
    }
  }, [isOpen]);

  const startCountdown = () => {
    setTimeRemaining(60 * 1); // Set 15 minutes (or any other duration)
  };

  // Update the timer every second
  useEffect(() => {
    if (!open || timeRemaining <= 0) return; // Stop timer when modal is closed or time is up

    const timerId = setInterval(() => {
      setTimeRemaining((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId); // Clear timer when component unmounts or modal closes
  }, [open, timeRemaining]);

  // Format time in HH:MM:SS
  const formatTime = (seconds) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };
  const joinSessionHandler = () => {
    if (intake_data && intake_data.custom_id && intake_data.redirect_url) {
      navigate(
        `/?custom_id=${intake_data.custom_id}&redirectURL=${intake_data.redirect_url}`
      );
      window.location.reload();
    } else {
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100000]"
        // onClose={() => {
        //   setOpen(false);
        //   closeCallback();
        // }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-black/20 backdrop-blur-md">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-white/70 backdrop-blur-lg overflow-hidden rounded-3xl px-4 pb-4 pt-5 text-left absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] transition-all sm:my-8 w-[90%] lg:w-[40vw] h-[80vh] md:h-[60vh] sm:p-6">
                <button
                  onClick={() => {
                    setOpen(false);
                    closeCallback();
                  }}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 bg-white rounded-full p-1.5 border border-gray-300"
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="relative">
                  <img
                    src="/assets/timer.png"
                    className="p-10 md:w-80 md:h-80 mx-auto"
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-4xl font-bold text-[var(--primary-color)]">
                      {formatTime(timeRemaining)}
                    </span>
                  </div>
                </div>
                <p className="text-center text-2xl font-semibold text-[var(--primary-color)]">
                  Your slot is free now, would you like to join?
                </p>
                <div className="flex justify-center gap-4 mt-4">
                  <button
                    className="text-xl border border-[var(--primary-color)] px-4 py-2 rounded-full"
                    onClick={() => {
                      setOpen(false);
                      closeCallback();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={joinSessionHandler}
                    className="text-xl border text-white bg-[var(--primary-color)] px-4 py-2 rounded-full"
                  >
                    Join Now
                  </button>
                </div>
                <p className="text-center text-sm mt-4 md:mt-10 text-[var(--primary-color)]">
                  Your slot will be allotted to the next user if there is no
                  response.
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
