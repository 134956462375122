import React, { useState } from "react";
import VideoModal from "../Modals/VideoModal";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { PlayArrow } from "@mui/icons-material";

const WaitingVideoCard = ({ handleCardClick, activeIndex, index, videos }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <div className=" m-0 mx-2 rounded-lg relative">
        <div className="relative">
          <img
            src={videos.data?.coverImageUrl}
            className=" rounded-lg h-[210px] w-full  object-cover"
            alt=""
          />
          <button
            className="bg-white absolute top-[50%] translate-y-[-50%] left-1/2 translate-x-[-50%] shadow-lg rounded-full p-2 z-[1000]"
            onClick={openModal}
          >
            <PlayArrow
              className="text-[var(--primary-color)]"
              sx={{ fontSize: "30px" }}
            />
          </button>
          <div className="absolute h-full bg-gradient-to-t from-black rounded-b-lg flex items-end to bottom-0 left-0 w-full  ">
           <div className="flex items-center ps-4 pb-4 gap-2">
            <div className="w-1 bg-white h-12"></div>
            <p className=" text-white">{videos.data?.title}</p>
            </div> 
          </div>
        </div>

        {/* <div
          className=" h-[200px] relative"
          style={{ backgroundImage: `url(${videos.data?.coverImageUrl})` }}
        >
          
          
         
          <div className="">{videos.data?.title}</div>
        </div> */}
      </div>

      <VideoModal
        isOpen={isModalOpen}
        closeCallback={closeModal}
        videos={videos}
      />
    </>
  );
};

export default WaitingVideoCard;
