import React from "react";


interface StellaAvatarProps {
  person: {
    name: string;
    imageUrl: string;
    desc: string;
    languages: string[];
    background: string;
    approach: string;
    expertise: string;
  };
  index: number;
  execute: (id: number) => void;
  character: number;
  setCharacter: (item: any) => void;
  setLoader: (item: any) => void;
}

const StellaAvatar: React.FC<StellaAvatarProps> = ({
  person,
  character,
  setLoader,
  execute,
  setCharacter,
  index,
}) => {


  return (
    <div className={`${character === 4
      ? "border-[3px] border-[var(--primary-base-color)] p-[2px] sm:rounded-[40px] rounded-3xl"
      : "border-[3px] border-white p-[2px] rounded-[40px]"
      }`}
      onClick={() => {
        setLoader(true);
        setCharacter(4)
        execute(4)
      }}>
      <div
        key={person.name}
        className={`group relative border-2 border-[var(--secondaty-base-gradiant-color] cursor-pointer rounded-3xl sm:rounded-[35px] overflow-hidden max-w-[400px] pt-4 sm:pt-8 transition-all 
        ${character === 4
            ? "bg-gradient-to-br from-[var(--primary-base-gradiant-color)] to-[var(--secondaty-base-gradiant-color)]"
            : "bg-gradient-to-b from-secondaty-base-gradient to-primary-base-opacity50"
          } 
        duration-300 hover:bg-gradient-to-b hover:from-[var(--primary-base-gradiant-color)] hover:to-[var(--secondaty-base-gradiant-color)]`}
      >
        <h3 className={`absolute left-4 top-1 ${character === 4 ?
          "text-white" : " text-black"
          } sm:top-4 sm:left-8 sm:flex my-2 text-base sm:text-xl font-bold leading-normal text-[#101828] group-hover:text-white`}>
          {person.name}
        </h3>
        <img
          className="aspect-[1/1] rounded-[1.25rem] object-cover"
          src={person.imageUrl}
          alt={person.name}
        />
      </div>
    </div>
  );
};

export default StellaAvatar;
