import React,{useEffect}  from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import StellaChat from "./Routes/StellaChat";
import FeedbackModal from "./Feedback/FeedbackModal";
import ThemeSwitcher from "./ThemeSwitcher";
import WaitingToJoinSession from "./Routes/WaitingToJoinSession.tsx";
import ThankYouPage from "./Routes/ThankYouPage";
import { signInAnonymously } from "firebase/auth";
import { auth } from "./config/firebaseConfig";
import ParticleLoader from "./Components/ParticleLoader";

function App() {
  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("ERROR", errorMessage);
      });
  }, []);

  return (
    <>
      <BrowserRouter>
        <ThemeSwitcher />
        <Routes>
          <Route path="/" element={<StellaChat />} />
          <Route path="/loader_components" element={<ParticleLoader />} />
          <Route path="/feedback" element={<FeedbackModal />} />
          <Route
            path="/waiting-to-join-session"
            element={<WaitingToJoinSession />}
          />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
