export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { ActionTypes } from "../reducer/actionTypes";

import axios from "axios";

export const fileUploadImage =
  "https://images.unitedwecare.com/odoo%2Fopen%2Fstella%2FtypeFile.webp";


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getComputedStyleVariable = (variable) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

export const primaryColorValue = getComputedStyleVariable("--primary");
export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const soundMimeType = isSafari()
  ? "audio/mp4"
  : "audio/webm;codecs=opus";


export const convertBase64ToBlob = (base64) => {
  const byteString = atob(base64?.split(",")[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: "image/png" });
};

export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" && value.trim() === "") {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return false;
};

export const fetchPrimaryColor = async (country) => {
  const response = await fetch(`/api/colors?country=${country}`);
  const data = await response.json();
  return data.primaryColor;
};

export const hexToHSL = (hex) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;
  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return {
    hue: Math.round(h * 360),
    saturation: Math.round(s * 100),
    lightness: Math.round(l * 100),
  };
};

// CRYPTO MODULES
// Encrypt the payload using Web Crypto API
export const encryptPayload = async (
  payload: Record<string, any>,
  secretKey: string
): Promise<{ iv: number[]; data: number[] }> => {
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization Vector
  const encoder = new TextEncoder();
  const keyData = encoder.encode(secretKey);

  // Import the key
  const key = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "AES-GCM" },
    false,
    ["encrypt"]
  );

  // Encode and encrypt the payload
  const encodedPayload = encoder.encode(JSON.stringify(payload));
  const encrypted = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encodedPayload
  );

  return {
    iv: Array.from(iv), // Convert the IV to a normal array for easy JSON serialization
    data: Array.from(new Uint8Array(encrypted)), // Convert the encrypted data to a normal array
  };
};

// Function to decrypt the encrypted data
export const decryptPayload = async (
  encryptedPayload: { iv: number[]; data: number[] },
  secretKey: string
): Promise<Record<string, any>> => {
  const { iv, data } = encryptedPayload;
  const ivArray = new Uint8Array(iv); // Convert IV back to Uint8Array
  const encryptedArray = new Uint8Array(data); // Convert data back to Uint8Array

  const encoder = new TextEncoder();
  const keyData = encoder.encode(secretKey);

  // Import the key
  const key = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "AES-GCM" },
    false,
    ["decrypt"]
  );

  // Decrypt the data
  const decrypted = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: ivArray,
    },
    key,
    encryptedArray
  );

  // Decode the decrypted data back to a string
  const decoder = new TextDecoder();
  const decryptedText = decoder.decode(decrypted);

  // Parse and return the JSON object
  return JSON.parse(decryptedText);
};

export const downloadFile = async (url: string, filename: string) => {
  try {
    const response = await axios.get(url, {
      responseType: "blob", // Get the response as a blob
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"] || "application/octet-stream", // Default to binary stream if content-type is not provided
    });

    // Create a link element
    const link = document.createElement("a");
    const href = window.URL.createObjectURL(blob);
    link.href = href;
    link.download = filename;

    // Append link to the body, click to trigger download, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL after download
    window.URL.revokeObjectURL(href);
  } catch (error) {
    console.error("Error downloading the file:", error);
    alert("There was an error downloading the file. Please try again later.");
  }
};

export const createPayload = (state: any, type: string, value: string) => {
  let payload = {};

  if (state.isUserLoggedIn && state.sessionID !== "") {
    switch (type) {
      case "mood":
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            mood: value,
          },
        };

        break;
      case "message":
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            message: value,
          },
        };

        break;

      case "button":
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            button_id: value,
          },
        };

        break;

      case "notification":
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            notification_id: value,
          },
        };

        break;

      case "payload":
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            payload: value,
          },
        };
        break;

      default:
        payload = {
          jsonrpc: "2.0",
          params: {
            sender_id: state.sessionID,
            message: "Hi!",
          },
        };
        return;
    }
  }

  return payload;
};

export const websiteRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i;


  export const getSubDomain = () => {
    const host = window.location.host;
    const domainPattern = /([a-zA-Z0-9-]+)\.(com|ai|com.au)$/; // Match domain like "unitedwecare.com" or "ask-sam.ai"
  
    const match = host.match(domainPattern);
  
    if (match) {
      return `${match[1]}.${match[2]}`; // Returns "unitedwecare.com" or "ask-sam.ai"
    }
  
    console.error("HOST didn't match domainPattern! Returning host as is!");
    return `${host}`;
  };