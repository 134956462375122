"use client";
import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  useRef,
  useReducer,
} from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  closePixelStreamInstance,
  getCustomDataApiHandler,
  Odoo_community_get_user_details,
  validate_token,
} from "./APIHandler/ApiHandler";
import { decodeToken } from "react-jwt";
import Cookies from "js-cookie";
import axios from "axios";
import { appReducer, initialState } from "./reducer/appReducer";
import { getSubDomain } from "./lib/utils";

const AuthContext = createContext();

function generateRandomToken(length) {
  return window.crypto
    .getRandomValues(new Uint8Array(length))
    .reduce((acc, item) => acc + ("0" + item.toString(16)).slice(-2), "");
}

export const AuthProvider = ({ children }) => {
  const sub_domain = getSubDomain();
  const [randomGeneratedToken, setRandomGeneratedToken] = useState(null);
  const [characterSelectionLoader, setCharacterSelectionLoader] =
    useState(false);
  const [streamingLoader, setStreamingLoader] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [currentUser, setCurrentUser] = useState(() =>
    localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile"))
      : null
  );
  const [customUserData, setCustomUserData] = useState(null);
  const cookie_token = Cookies.get("authToken");
  const [stellaData, setStellaData] = useState({
    chatHistory: [],
  });
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [socketToken, setSocketToken] = useState(null);
  const session_id = generateRandomToken(16);
  const socketRef = useRef(null);
  const [openWaitingToJoinSession, setWaitingToJoinSession] = useState(false);
  const [openStrimAfterLoadingSection, setOpenStreamAfterLoadingSection] =
    useState(null);

  const logoutHandler = async () => {
    closePixelStreamInstance(currentUser?.UserId).then((res) => {
      if (res?.result === "closed") {
        let login_detail_str = window.localStorage.getItem("Profile");
        if (login_detail_str) {
          window.location.reload();
        } else {
          console.error("Profile not found in localStorage.");
        }
      }
    });
    if (Cookies.get("authToken")) {
      Cookies.remove("authToken", {
        domain: `.${sub_domain}`,
        path: "/",
        // secure: true,
        HttpsOnly: true,
      });
    }
    let url = window.location.href;
    window.location.href =
      url?.includes("&custom_id") || url?.includes("&redirectURL")
        ? url.replace(/&token=[^&]*/, "")
        : url;
  };

  const contextValue = {
    logoutHandler,
    state,
    dispatch,
    userDetails,
    currentUser,
    socketRef,
    stellaData,
    setStellaData,
    randomGeneratedToken,
    setRandomGeneratedToken,
    characterSelectionLoader,
    setCharacterSelectionLoader,
    streamingLoader,
    setStreamingLoader,
    setCustomUserData,
    customUserData,
    setWaitingToJoinSession,
    openWaitingToJoinSession,
    openStrimAfterLoadingSection,
    setOpenStreamAfterLoadingSection,
  };

  useEffect(() => {
    if (!typeof window) return;

    if (Cookies.get("authToken")) {
      setCharacterSelectionLoader(true);
      validate_token(Cookies.get("authToken")).then((resp) => {
        if (resp?.ResponseCode === 200) {
          localStorage.setItem(
            "profile",
            JSON.stringify(resp?.data?.userdetails)
          );
          localStorage.setItem("Authorization", resp?.data?.usertoken);
          const decodedToken = decodeToken(
            resp?.data?.usertoken?.split("Bearer ")[1]
          );
          setStellaData({
            token: resp?.data?.usertoken?.split("Bearer ")[1],
            session_id,
            profile: {
              ...resp?.data?.userdetails,
              user_id: resp?.data?.userdetails?.UserId,
              temp_user_id: decodedToken?.temp_user_id,
              user_logedin: true,
            },
          });
        }
      });
    } else {
      const params = new URLSearchParams(window.location.search);
      const customId = params.get("custom_id"); // this will get the custom_id parameter
      const redirect_url = params.get("redirect");

      // Correct the key name to match custom_id
      const intake_data = {
        custom_id: customId, // corrected from custome_id to custom_id
        redirect_url: redirect_url,
      };

      // Set the cookie with corrected intake_data
      // Cookies.set("intake_data", JSON.stringify(intake_data));
      window.localStorage.setItem("intake_data", JSON.stringify(intake_data));
      window.location.href = `https://account.asksam.com.au/?redirect=${window.location.href}`;
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const customId = params.get("custom_id"); // this will get the custom_id parameter
    const redirect_url = params.get("redirectURL");

    // Correct the key name to match custom_id
    const intake_data = {
      custom_id: customId, // corrected from custome_id to custom_id
      redirect_url: redirect_url,
    };

    // Set the cookie with corrected intake_data
    // Cookies.set("intake_data", JSON.stringify(intake_data));
    window.localStorage.setItem("intake_data", JSON.stringify(intake_data));

    if (customId) {
      getCustomDataApiHandler(customId).then((customData) => {
        if (customData && customData.code === 200) {
          setCustomUserData(customData.data);
          console.log("Fetched custom data:", customData);
        } else {
          console.log("No data retrieved.");
        }
      });
    }
  }, []);

  useEffect(() => {
    const generateToken = async () => {
      try {
        const response = await axios.post(
          "https://socket.mystella.ai/stella/get-token",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setRandomGeneratedToken(response.data?.token);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };
    generateToken();
  }, []);

  // Function to initialize or reinitialize the WebSocket connection
  const initSocketConnection = (token, Socket_type) => {
    if (socketRef.current) {
      socketRef.current.close();
    }

    socketRef.current = new ReconnectingWebSocket(
      `wss://socket.mystella.ai/ws?authorization=${token}`
    );

    socketRef.current.addEventListener("open", () => {
      setStellaData((prevData) => ({
        ...prevData,
        chatHistory: [],
      }));
    });

    socketRef.current.addEventListener("message", (event) => {
      const resp_data = JSON.parse(event.data);
      if (resp_data?.type === 0 && resp_data?.login_request?.success) {
        const newToken = resp_data?.token;
        const decodedToken = decodeToken(newToken);
        const user_data = resp_data?.login_request?.data;
        const localProfile = JSON.parse(localStorage.getItem("Profile")) || {};

        setUserDetails({
          user_name: user_data?.display_name,
          Emailid: localProfile?.Emailid || user_data?.email,
          Name: localProfile?.Name || user_data?.name,
          profile_image: user_data?.profile_image,
          user_id: decodedToken?.user_id,
          temp_user_id: decodedToken?.temp_user_id,
        });

        document.cookie = `authDataJwt=${newToken}; expires=${new Date(
          decodedToken.exp * 1000
        ).toUTCString()}; path=/;`;

        setStellaData((prevData) => ({
          ...prevData,
          profile: {
            display_name:
              localProfile?.given_name ||
              resp_data?.login_request?.data?.display_name,
            email: localProfile?.email || resp_data?.login_request?.data?.email,
            name: localProfile?.name || resp_data?.login_request?.data?.name,
            profile_image:
              localProfile?.picture ||
              resp_data?.login_request?.data?.profile_image,
            user_id: decodedToken?.user_id,
            temp_user_id: decodedToken?.temp_user_id,
          },
          token: newToken,
        }));

        // Set token for next reconnection if needed
        setSocketToken(newToken);
      } else {
        if (!resp_data?.question) return;

        setStellaData((prevData) => {
          const chatHistory = prevData?.chatHistory || []; // Ensure chatHistory is always an array

          if (resp_data?.type === 1) {
            return {
              ...prevData,
              modalData: {
                show: true,
                ...resp_data,
                questionIndex: chatHistory.length,
              },
              chatHistory: [
                ...chatHistory,
                { ...resp_data, liked: false, time_stamp: new Date() },
              ],
            };
          } else {
            return {
              ...prevData,
              chatHistory: [
                ...chatHistory,
                { ...resp_data, liked: false, time_stamp: new Date() },
              ],
            };
          }
        });
      }
    });

    socketRef.current.addEventListener("error", (error) => {
      console.error("WebSocket Error:", error);
    });

    socketRef.current.addEventListener("close", () => {
      console.log("WebSocket connection closed!");
    });
  };

  useEffect(() => {
    if (randomGeneratedToken) {
      initSocketConnection(randomGeneratedToken, "Initial_Connection");
    }
    setTimeout(() => {
      const payload = {
        user_id: currentUser?.UserId,
        type: 0,
        login_response: {
          email: currentUser?.Emailid,
          type: "send_otp",
        },
        data_type: "",
        classify: "User: ",
        time_stamp: Date.now(),
      };

      setStellaData((prevData) => ({
        ...prevData,
        login_type: "email",
      }));
      if (socketRef?.current) {
        socketRef.current.send(JSON.stringify(payload));
      }
    }, 3000);
  }, [randomGeneratedToken]);

  useEffect(() => {
    if (socketToken && socketToken !== randomGeneratedToken) {
      initSocketConnection(socketToken, "Verify_Token_Connection");
    }
  }, [socketToken]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
