import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AddDocument,
  closePixelStreamInstance,
  GetMyDocuments,
  GetSessionCountData,
  runPixelStreamInstance,
  SendUploadedDoc,
} from "../APIHandler/ApiHandler";
import { useAuth } from "../Authenticator";
import { PixelStreamingWrapper } from "../Components/PixelStreamingWrapper.tsx";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { LoaderCircle, X } from "lucide-react";
import {
  CallEndOutlined,
  ChatOutlined,
  Favorite,
  SpeakerNotesOffOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { Switch } from "@headlessui/react";
import StellaConversationViewer from "../Components/StellaConversationViewer.jsx";
import HandleRecording from "../Components/HandleRecording.jsx";
import VideoRecorder from "../Components/VideoRecorder.jsx";
import DocumentAttachment from "../Components/DocumentAttachment.jsx";
import Cookies from "js-cookie";
import UploadFileDrop from "../Components/UploadFileDrop.tsx";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../config/firebaseConfig";
import { fileUploadImage } from "../lib/utils";
import dayjs from "dayjs";
import ParticleLoader from "../Components/ParticleLoader";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function StellaChat() {
  const {
    stellaData,
    setStellaData,
    socketRef,
    characterSelectionLoader,
    setCharacterSelectionLoader,
    customUserData,
    currentUser,
    state,
    dispatch,
  } = useAuth();

  // const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const cookie_token = Cookies.get("authToken");
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("");
  const textareaRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState(0);
  const [showChat, setShowChat] = useState(true);
  const [shouldchat, setShouldChat] = useState(false);
  const [stellaReaction, setStellaReaction] = useState(false);
  const [startCamera, setStartCamera] = useState(false);
  const [showHeartbeat, setShowHeartbeat] = useState(false);
  const [fileUploaded, setFileUploaded] = useState("idle");
  const [uploadedFileLink, setUploadedFileLink] = useState(null);
  const uniqueID = crypto.randomUUID().toString();

  useEffect(() => {
    // const startSessionandler = async () => {
    //   try {
    //     const sessionData = await GetSessionCountData();
    //     if (sessionData && Object.keys(sessionData)?.length >= 1) {
    //       navigate("/waiting-to-join-session");
    //     } else {
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    if (stellaData?.profile?.UserId && stellaData?.profile?.user_logedin) {
      LaunchprocessIds();
    }
  }, [stellaData?.profile?.UserId]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Ensure the textarea is mounted
      if (value.trim() === "") {
        console.log("in");
        textarea.style.height = "48px";
        setTextareaHeight(48);
      } else {
        textarea.style.height = "25px"; // Reset height to calculate scrollHeight correctly
        textarea.style.height = `${Math.min(textarea.scrollHeight, 125)}px`;
        setTextareaHeight(Math.min(textarea.scrollHeight, 125));
      }
    }
  }, [value]); // Re-run effect when 'value' changes

  const LaunchprocessIds = async () => {
    let login_detail = JSON.parse(window.localStorage.getItem("Profile"));
    setLoader(true);
    const resp = await runPixelStreamInstance(stellaData?.profile?.user_id);
    if (resp.success) {
      setTimeout(() => {
        setStellaData({
          ...stellaData,
          PortsInfo: resp,
        });

        setLoader(false);
      }, 15000);

      window.localStorage.setItem(
        "Profile",
        JSON.stringify({
          ...login_detail,
          can_chat_with_stella: true,
        })
      );
      setTimeout(() => {
        setCharacterSelectionLoader(false);
      }, 15000);
    } else {
      enqueueSnackbar(resp.message, {
        variant: "error",
      });
      navigate("/waiting-to-join-session");
      window.localStorage.setItem(
        "Profile",
        JSON.stringify({
          ...login_detail,
          can_chat_with_stella: false,
        })
      );
      setTimeout(() => {
        setCharacterSelectionLoader(false);
      }, 15000);
    }
  };

  const handleSendMessage = async (data) => {
    console.log(data?.message);
    setShouldChat(false);
    setValue("");
    // To show circular loader upload file into message chat
    setFileUploaded("uploading");

    const type = data?.options ? 1 : 2;
    let currDataNew = {
      service_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.service_id || 50, // service identifier
      message_id: "",
      question_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.question_id || 0, //question_id
      type: type,
      response: data?.message || data?.option?.option_display_name, // option_display_name
      time_stamp: Date.now(),
      session_id: stellaData?.session_id,
      user_id: stellaData?.profile?.UserId,
      registered_id: stellaData?.profile?.UserId,
      classify: "User: ",
      responseFrom: "User",
      options:
        data?.options ||
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]?.options ||
        [],
      mode: customUserData?.mode?.code || "gn01",
      welcome_message: customUserData?.welcome_message,
      expert_id: customUserData?.expert?.id,
      documentUrl: uploadedFileLink !== null ? uploadedFileLink : undefined,
    };
    //send response to socket if messafe is not empty
    if (data?.message?.trim() !== "") {
      socketRef?.current?.send(JSON.stringify(currDataNew));
    }

    let temp = [...stellaData?.chatHistory];
    if (data?.options?.length > 0) {
      console.log("inside options");
      temp[data?.questionIndex] = {
        ...temp[data?.questionIndex],
        options: data?.options,
        disabled: true,
      };
    }
    if (uploadedFileLink) {
      await sendDataToSTT(
        data?.message || data?.option?.option_display_name,
        uploadedFileLink
      );
    }
    setStellaData({
      ...stellaData,
      modalData: {},
      chatHistory: [...temp, currDataNew],
    });
    setUploadedFileLink(null);
    setFileUploaded("idle");
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (e) => {
    console.log(e);

    if (e && e.code === "Enter" && !e.shiftKey) {
      e.preventDefault();

      handleSendMessage({ message: value });
      setValue("");
    }
  };

  let wordCount = 0;
  let previousMultiple = 0;

  const handleTranscript = (transcript) => {
    if (transcript.trim() === "") {
      wordCount = 0;
      previousMultiple = 0;

      return;
    }
    const words = transcript?.split(/\s+/);
    wordCount += words.length;

    setValue(transcript);
    // Check if the current word count is a new multiple of 10
    if (wordCount % 15 === 0 && wordCount !== previousMultiple) {
      console.log("Called function");
      setStellaReaction(true);
      // Update the previous multiple of 10
      previousMultiple = wordCount;
    } else {
      setStellaReaction(false);
    }
  };

  const handleEOC = () => {
    socketRef?.current?.send(
      JSON.stringify({
        service_id: 50, // service identifier
        message_id: "",
        question_id: "", //question_id
        type: "",
        response: "<|end_of_session|>", // option_display_name
        time_stamp: Date.now(),
        session_id: stellaData?.session_id,
        user_id: stellaData?.profile?.UserId,
        classify: "User",
        responseFrom: "User",
        registered_id: stellaData?.profile?.UserId,
        mode: customUserData?.mode?.code || "gn01",
        welcome_message: customUserData?.welcome_message,
        expert_id: customUserData?.expert?.id,
      })
    );

    closePixelStreamInstance(currentUser?.UserId).then((res) => {
      if (res?.result === "closed") {
        let login_detail_str = window.localStorage.getItem("Profile");
        if (login_detail_str) {
          let login_detail = JSON.parse(login_detail_str);
          window.localStorage.setItem(
            "Profile",
            JSON.stringify({
              ...login_detail,
              can_chat_with_stella: false,
            })
          );
          navigate("/feedback");
          // window.location.reload();
        } else {
          console.error("Profile not found in localStorage.");
        }
      }
    });
  };

  const handleHeart = () => {
    setShowHeartbeat(!showHeartbeat);
    setShouldChat(false);
    let currDataNew = {
      service_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.service_id || 50, // service identifier
      message_id: "",
      question_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.question_id || 0, //question_id
      type: 2,
      response: "sending you some love...", // option_display_name
      time_stamp: Date.now(),
      session_id: stellaData?.session_id,
      user_id: stellaData?.profile?.UserId,
      registered_id: stellaData?.profile?.UserId,
      classify: "User: ",
      responseFrom: "User",
      options: null,
      mode: customUserData?.mode?.code || "gn01",
      welcome_message: customUserData?.welcome_message,
      expert_id: customUserData?.expert?.id,
    };

    socketRef?.current?.send(JSON.stringify(currDataNew));
    setTimeout(() => {
      setShowHeartbeat(false);
    }, 6000);
  };

  // const handleDocumentUpload = (ocrText) => {
  //   let currDataNew = {
  //     service_id:
  //       stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
  //         ?.service_id || 50, // service identifier
  //     message_id: "",
  //     question_id:
  //       stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
  //         ?.question_id || 0, //question_id
  //     type: 2,
  //     response: "Explain me what is in the document?", // option_display_name
  //     time_stamp: Date.now(),
  //     session_id: stellaData?.session_id,
  //     user_id: stellaData?.profile?.UserId,
  //     registered_id: stellaData?.profile?.UserId,
  //     classify: "User: ",
  //     responseFrom: "User",
  //     options: null,
  //     mode: customUserData?.mode?.code || "gn01",
  //     welcome_message: customUserData?.welcome_message,
  //     expert_id: customUserData?.expert?.id,
  //     // ocr_text: ocrText,
  //   };

  //   socketRef?.current?.send(JSON.stringify(currDataNew));
  // };

  /*--------------  File Upload Functions ----------------------*/

  const handleFile = (file, fileType) => {
    handleUpload(file, fileType);
    //console.log("fileType", fileType);
  };
  //console.log("day: ", dayjs().format("YYYY-MMM-DD"));
  const handleUpload = async (file, fileType) => {
    try {
      //const dirName = dayjs().format("MMMM_YYYY");
      const storageRef = ref(
        storage,
        `/clinic-data-storage/clients/${currentUser?.Country}/${dayjs().format(
          "YYYY-MMM-DD_HH:mm:ss"
        )}_${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      // console.log("fileType", fileType);

      setFileUploaded("uploading");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          //console.log("Uploading...", percent);
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);

            if (fileType === "FILE") {
              setFileUploaded(fileUploadImage);
            } else {
              setFileUploaded(url);
            }
            setUploadedFileLink(url);
            // await sendDataToSTT(actualLastMessage, "Upload_Processing");
          } catch (error) {
            console.error("Error getting download URL:", error);
            // Handle error if needed
          }
        }
      );
    } catch (error) {
      console.error("Error while uploading", error);
      setFileUploaded("idle");
      setUploadedFileLink(null);
    }
  };

  const sendDataToSTT = async (lastMessage, uploadedFileLink) => {
    console.log("actualLastMessage", lastMessage);
    try {
      const body = {
        image_url: uploadedFileLink,
        lmm_prompt: "",
        llm_prompt: "",
        max_tokens: 0,
        temperature: 0,
        text: lastMessage,
      };
      const responseData = await SendUploadedDoc(JSON.stringify(body));
      if (responseData?.status == 200) {
        const addBody = {
          uploaded_by: "user",
          user_id: currentUser?.UserId + "",
          creator_id: currentUser?.UserId + "",
          clinical_note_id: null,
          //datetime: "2024-08-16T14:17:23.990717",
          doctor_names: responseData?.data?.doctor_names || "",
          response: responseData?.data?.response,
          clinical_relevance: responseData?.data?.clinical_relevance,
          metadata: responseData?.data?.metadata,
        };

        const addDocResponse = await AddDocument(addBody);
      }
    } catch (error) {
      const newMessage = {
        _id: uniqueID,
        response: "server",
        data: "I'm sorry, I ran into a problem while uploading your document. Could you please try uploading again?",
        avatar: state.selectedFace,
      };
    }
  };

  return (
    <div className="min-h-screen w-screen flex  bg-[#DFE1E5]">
      {characterSelectionLoader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            zIndex: 10000000,
          }}
          // className="background-gradient"
        >
          {/* <div className="pulse before:bg-white after:border-white top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]"></div> */}
          <ParticleLoader />
        </div>
      )}

      <div className={` w-full  ${showChat ? "" : " "}  relative  `}>
        {stellaData?.PortsInfo?.httpsPort && (
          <PixelStreamingWrapper
            initialSettings={{
              AutoPlayVideo: true,
              AutoConnect: true,
              ss:
                process.env.REACT_APP_WEBSOCKET_URL +
                ":" +
                stellaData?.PortsInfo?.httpsPort,
              StartVideoMuted: false,
              HoveringMouse: true,
              MatchViewportRes: true,
              MouseInput: true,
              TouchInput: false,
              preferSFU: true,
              WebRTCMaxBitrate: 2000000,
            }}
            processIds={{
              ...stellaData?.PortsInfo,
              userID: stellaData?.profile?.UserId,
              sessionID: stellaData?.session_id,
            }}
            selectedStream={(data) => {
              if (data?.selected) {
                setStellaData({
                  ...stellaData,
                  characterProfile: data,
                });
                setTimeout(() => {
                  socketRef?.current?.send(
                    JSON.stringify({
                      service_id: 50, // service identifier
                      message_id: "",
                      question_id: "", //question_id
                      type: "",
                      response: "Hello! I am Sam", // option_display_name
                      time_stamp: Date.now(),
                      session_id: stellaData?.session_id,
                      user_id: stellaData?.profile?.UserId,
                      classify: "User",
                      responseFrom: "User",
                      registered_id: stellaData?.profile?.UserId,
                      mode: customUserData?.mode?.code || "gn01",
                      welcome_message: customUserData?.welcome_message,
                      expert_id: customUserData?.expert?.id,
                    })
                  );
                }, 1000);
              }
              if (data?.speak) {
                setShouldChat(true);
              }
            }}
            stellaReaction={stellaReaction}
          />
        )}

        {/* VideoCam Feed */}
        <div className="absolute top-[10%]  w-36 sm:w-60 md:aspect-[3/4] aspect-[9/18] left-[7%] p-2 z-[50]">
          <VideoRecorder
            startCam={startCamera}
            sessionId={stellaData?.session_id}
            token={stellaData?.token}
          />
        </div>

        <div
          className="absolute bottom-16 hidden lg:flex left-1/2 translate-y-[-50%] translate-x-[-50%] rounded-full glass  gap-7 p-2 z-[1000] "
          style={{
            background:
              "linear-gradient(254.47deg, rgba(255, 255, 255, 0.2) 10.81%, rgba(255, 255, 255, 0.2) 117.24%)",
          }}
        >
          {!loader && (
            <HandleRecording
              handleTranscript={handleTranscript}
              shouldchat={shouldchat}
            />
          )}

          <button
            className={`w-[50px] aspect-square disabled:cursor-not-allowed cursor-pointer flex flex-col justify-center items-center rounded-full ${
              startCamera ? "bg-[var(--primary-base-color)]" : ""
            }`}
            disabled={!shouldchat}
            onClick={() => setStartCamera(!startCamera)}
          >
            {startCamera ? (
              <VideocamOutlined className={`text-white`} />
            ) : (
              <VideocamOffOutlined className={`text-white `} />
            )}
          </button>
          <div
            className={`w-[50px] h-[50px]  aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full ${
              showChat ? " bg-[var(--primary-base-color)]" : ""
            }`}
            onClick={() => {
              setShowChat(!showChat);
            }}
          >
            {showChat ? (
              <ChatOutlined className="text-white" />
            ) : (
              <SpeakerNotesOffOutlined className="text-white" />
            )}
          </div>
          <div
            onClick={handleEOC}
            className="w-[50px] h-[50px] bg-white aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full"
          >
            <CallEndOutlined className=" text-red-600 rounded-full " />
          </div>
        </div>
        {showHeartbeat && (
          <ul className="hearts  z-[1000000] flying">
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
          </ul>
        )}

        <button
          disabled={!shouldchat}
          onClick={handleHeart}
          className="flex w-[50px] h-[50px] disabled:cursor-not-allowed  z-[100]  bg-[#ff5b5b] rounded-full absolute  bottom-20 sm:bottom-5 lg:bottom-24 left-2 lg:left-[7%]   cursor-pointer shadow-lg shadow-[#fe6c6c9d]    justify-evenly items-center"
        >
          <Favorite className="text-white" />
        </button>
      </div>
      <div className="absolute flex items-center lg:hidden z-[1000] top-[5%] right-3 ">
        {!window.ReactNativeWebView && (
          <UploadFileDrop handleFile={handleFile} />
        )}
        {/* <DocumentAttachment handleTranscript={handleDocumentUpload} /> */}
        <Switch
          checked={showChat}
          onChange={(e) => {
            setShowChat(e);
          }}
          className={classNames(
            "bg-[#F6EFFA] relative inline-flex h-[35px] w-[70px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-none"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            className={classNames(
              showChat ? "translate-x-9" : "translate-x-0",
              "pointer-events-none relative inline-block h-[30px] w-[30px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          >
            <span
              className={classNames(
                showChat
                  ? "opacity-0 duration-100 ease-out"
                  : "opacity-100 duration-200 ease-in",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <img src="/assets/Icons/showchatoff.png" />
            </span>
            <span
              className={classNames(
                showChat
                  ? "opacity-100 duration-200 ease-in"
                  : "opacity-0 duration-100 ease-out",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <img src="/assets/Icons/showchat.png" />
            </span>
          </span>
        </Switch>
      </div>
      <div
        className={`absolute   h-screen lg:bg-transparent pt-60 lg:pt-0 m-0 bottom-0 right-0 z-50 w-full lg:w-[400px] lg:h-[80vh] lg:ring-1 lg:ring-gray-900/10 lg:top-[46%] lg:right-[1%] lg:-translate-x-[5%] lg:-translate-y-1/2 lg:rounded-xl xs:bottom-5 ${
          showChat
            ? "bg-gradient-to-t lg:bg-white/10 flex lg:flex-col"
            : "lg:hidden"
        } from-[#000000c5] to-[#00000000] lg:from-transparent lg:to-transparent lg:backdrop-blur-sm`}
      >
        <div className=" bg-white lg:bg-white/40 h-[82px] lg:h-max  w-full hidden lg:flex p-10 py-5 font-bold lg:z-10  text-2xl lg:text-lg lg:py-3 lg:rounded-t-xl">
          Chats
        </div>

        <div className="lg:flex absolute lg:relative bottom-0 w-full lg:h-full lg:bg-white/30 lg:rounded-b-xl  flex-col justify-between  ">
          <StellaConversationViewer showChat={showChat} />

          <div
            className={` fixed w-full lg:relative bottom-0 px-2 flex gap-3  lg:bg-white/20 justify-between items-end  lg:rounded-b-xl`}
          >
            <div className="hidden lg:block pb-1 ps-2">
              {fileUploaded !== "idle" ? (
                <div className="p-2 w-full border-b border-solid border-slate-300">
                  <div className="w-12 h-auto aspect-square rounded-md border bg-slate-300/80 relative flex items-center justify-center">
                    {fileUploaded == "uploading" && (
                      <LoaderCircle className="w-6 h-6 animate-spin text-white" />
                    )}
                    {fileUploaded !== "idle" &&
                      fileUploaded !== "uploading" &&
                      uploadedFileLink && (
                        <div>
                          <button
                            className="p-0.5 rounded-full aspect-square bg-white border absolute -top-1 -right-1"
                            onClick={() => {
                              setFileUploaded("idle");
                              setUploadedFileLink(null);
                            }}
                          >
                            <X className="w-3.5 h-3.5 dark:text-black" />
                          </button>
                          <img
                            src={fileUploaded}
                            className="w-12 h-12 object-cover rounded-md"
                          />
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                !window.ReactNativeWebView && (
                  <UploadFileDrop handleFile={handleFile} />
                )
              )}
              {/* <DocumentAttachment handleTranscript={handleDocumentUpload} /> */}
            </div>

            <div className="lg:hidden  h-full min-w-[45px]  ">
              <button
                className={`min-w-[45px] h-[45px] bg-[var(--primary-base-color)] absolute bottom-[12px] lg:hidden aspect-square disabled:cursor-not-allowed cursor-pointer flex flex-col justify-center items-center rounded-full`}
                disabled={!shouldchat}
                onClick={() => setStartCamera(!startCamera)}
              >
                {startCamera ? (
                  <VideocamOutlined className={`text-white`} />
                ) : (
                  <VideocamOffOutlined className={`text-white `} />
                )}
              </button>
            </div>

            <div
              className="textarea-container w-full rounded "
              style={{
                minHeight: "64px",
                height:
                  textareaHeight > 125
                    ? 145
                    : textareaHeight === 48
                    ? "72px"
                    : textareaHeight + 30 + "px",

                position: "relative",
              }}
            >
              <textarea
                ref={textareaRef}
                value={value}
                // disabled={!shouldchat}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                style={{
                  position: "absolute",

                  width: "100%",
                  minHeight: "25px",
                  height: "25px",
                  maxHeight: "125px",
                  boxSizing: "border-box",
                  resize: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  background:
                    "linear-gradient(254.47deg, rgba(255, 255, 255, 0.2) 10.81%, rgba(255, 255, 255, 0.2) 117.24%)",
                }}
                className="lg:p-3 px-10 py-3 glass  rounded-s-3xl text-white lg:text-black left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] lg:bg-white rounded-e-3xl bg-transparent border-0 ring-0 outline-0"
                placeholder="Type Something..."
              />

              <div
                className="lg:hidden bottom-[15px]"
                style={{
                  position: "absolute",
                  right: 0,
                  zIndex: "1000000",
                }}
              >
                <IconButton
                  sx={{ padding: "3px" }}
                  onClick={() => handleSendMessage({ message: value })}
                  disabled={value?.trim() === "" && uploadedFileLink === null}
                >
                  <div className="min-h-[36px]  min-w-[36px] bg-[var(--primary-base-color)] lg:hidden flex  items-center justify-center rounded-full">
                    <img
                      src="../assets/Icons/send-2.png"
                      alt="Add User"
                      className="w-[24px]"
                    />
                  </div>
                </IconButton>
              </div>

              <div
                className="lg:hidden bottom-[18px] "
                style={{
                  position: "absolute",
                  // padding:"6px",
                  left: 4,
                  zIndex: "1000",
                }}
              >
                <HandleRecording
                  handleTranscript={handleTranscript}
                  shouldchat={shouldchat}
                />
              </div>
            </div>
            <div className="lg:hidden min-w-[50px] ">
              <div
                onClick={handleEOC}
                className="min-w-[50px] h-[50px] absolute bottom-[12px] bg-white aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full"
              >
                <CallEndOutlined className=" text-red-600 rounded-full " />
              </div>
            </div>

            <div className="hidden lg:block  pb-1 pe-2">
              <IconButton
                onClick={() => handleSendMessage({ message: value })}
                disabled={value?.trim() === "" && uploadedFileLink === null}
                className=""
              >
                <div className="min-h-[50px]  min-w-[50px] bg-[var(--primary-base-color)] hidden lg:flex  items-center justify-center rounded-full">
                  <img
                    src="../assets/Icons/send-2.png"
                    alt="Add User"
                    className="w-[30px]"
                  />
                </div>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StellaChat;
